<template>
	<v-container fluid style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('team.title') }}</span>
		</div>

		<v-divider class="my-2" />
		<v-row justify="center">
			<TeamMember v-for="member in teamMembers" :key="member.id" :member="member" />
		</v-row>
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('nav.team'),
			meta: [{ name: 'description', content: 'Team of OverSOS' }],
		}
	},
	data() {
		return {
			teamMembers: [
				{
					id: 0,
					name: 'Alex Álvarez',
					title: '',
					subtitle: '',
					img: 'alvarez.jpg',
					webURL: '',
					githubURL: 'https://github.com/jandrikus',
					instagramURL: '',
					linkedinURL: 'https://www.linkedin.com/in/alejandro-a-98703b87/',
					colLgSize: 3,
				},
				{
					id: 1,
					name: 'Daniel Peral',
					title: '',
					subtitle: '',
					img: 'peral.jpg',
					webURL: '',
					githubURL: '',
					instagramURL: '',
					linkedinURL: '',
					colLgSize: 3,
				},
			],
		}
	},
	components: {
		TeamMember: () => import('@/components/main/TeamMember.vue'),
	},
}
</script>
